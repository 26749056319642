.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 10px 15px;
    bottom: 85px;
    left: -175px;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1000;
    display: flex;
    align-items: center;
}

.tooltip p {
    font-size: 20px;
    margin-left: 10px;
    line-height: 30px;
    padding-top: 5px;
}
