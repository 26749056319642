.link {
  background-color: #CA1F34;
  padding: 9px 20px;
  color: #FFF;
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  display: block;
  text-align: center;
}

@media all and (max-width: 650px) {
  .container-mobile {
    flex-direction: column !important;
  }
}