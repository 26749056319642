.want-trade-btn, .work-here-btn {
  color: #FFF;
  background-color: #CA1F34;
  border-radius: 5px;
  max-width: 100% !important;
  width: 100% !important;
  border: none;
  font-size: 13px;
  padding: 9px 20px;
  cursor: pointer;
  margin-bottom: 10px;
}
